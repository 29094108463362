// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-service-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\templates\\service.js" /* webpackChunkName: "component---src-templates-service-js" */),
  "component---src-templates-payments-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\templates\\payments.js" /* webpackChunkName: "component---src-templates-payments-js" */),
  "component---src-templates-testimonial-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\templates\\testimonial.js" /* webpackChunkName: "component---src-templates-testimonial-js" */),
  "component---src-pages-404-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-index-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\contact\\index.js" /* webpackChunkName: "component---src-pages-contact-index-js" */),
  "component---src-pages-index-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-payments-index-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\payments\\index.js" /* webpackChunkName: "component---src-pages-payments-index-js" */),
  "component---src-pages-services-index-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\services\\index.js" /* webpackChunkName: "component---src-pages-services-index-js" */),
  "component---src-pages-testimonials-index-js": () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\src\\pages\\testimonials\\index.js" /* webpackChunkName: "component---src-pages-testimonials-index-js" */)
}

exports.data = () => import("D:\\gatsby_new\\app_1_backup_2\\app_2\\.cache\\data.json")

